<template>
  <v-container class="splash  ma-0 pa-0 pt-5">
    <v-row class="splash-main" :class="[isSafari ? 'safari' : 'not-safari']">
      <play-list :playlist="playlist.media" class="play-list" />
    </v-row>
    <v-row class="splash-footer" align="center" justify="center">
      <v-col class="ma-0 pa-0" cols="8" >
        Your departures will be displayed in
        <span style="font-weight: bold"> {{ timeOut }} seconds</span>
      </v-col>
      <v-col cols="4" class="ma-0 splash-btn-container">
        <v-img
          @click="closeSplash"
          :src="disableButton ? disabledSkipImage : enabledSkipImage"
          :aria-label= "ariaSkipButton"
          :alt="altSkipButton"
          class="mx-auto"
          :class="disableButton ? 'disabled-button-image' : ''"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PlayList from "./PlayList.vue";
import {  mapMutations, mapGetters, mapActions, mapState } from "vuex";

export default {
  components: {
    PlayList,
  },
  data() {
    return {
      enabledSkipImage: require("@/assets/icons/CancelBtn.svg"),
      disabledSkipImage: require("@/assets/icons/CancelGreyBtn.svg"),
      
      myTimer: null,

      intialTimeout: 0,      
      timeOut: 0,
      
      intialDisabledTimer: 0,
      disabledTimer: 0,

      initialDisabled: false,
      disableButton: false,
    };
  },
  mounted() {
    this.initialDisabled = process.env.VUE_APP_SPLASH_SCREEN_DISABLED_SKIP_BUTTON === "true";
    this.disableButton = (this.initialDisabled && !this.splashScreenViewed) &&
    (this.initialDisabled && !this.splashIdSameCookieValue);
    
    
    this.intialTimeout = parseInt(process.env.VUE_APP_SPLASH_SCREEN_TIMER, 10);
    this.timeOut = this.intialTimeout;
    
    this.intialDisabledTimer = parseInt(process.env.VUE_APP_SPLASH_SCREEN_DISABLE_DISMISS_TIMER, 10);
    this.disabledTimer = this.intialDisabledTimer;


    this.startTimer();
  },
  unmounted() {
    clearInterval(this.myTimer);
  },
  computed: {
    ...mapGetters(["cookiePreference", "splashIdSameCookieValue"]),
    ...mapState(["splashScreenViewed", "playlist"]),
    ariaSkipButton() {
      return this.disableButton ? `Skip button disabled for ${this.disabledTimer} seconds` : "Skip button";
    },
    altSkipButton() {
      return this.disableButton ? `Skip button disabled for ${this.disabledTimer} seconds` : "Skip button";
    },
    isSafari() {
      return false; 
    },
  },
  methods: {
    ...mapMutations(["setSplashScreenIdLastViewed","setDisableMenu"]),
    ...mapActions(["updateCurrentPage", "updateSplashScreenViewed"]),
    closeSplash() {
      if (this.disableButton) return;
      this.updateCurrentPage("timetable");
      this.isActive = false;
      this.updateSplashScreenViewed()
      this.setDisableMenu(false);
      if (this.cookiePreference) {
        this.setSplashScreenIdLastViewed(this.playlist.id);
      }
    },
    startTimer() {
      this.myTimer = setInterval(() => {
        this.timeOut--;
        this.disabledTimer--;
        if (this.timeOut <= 0) {
          this.closeSplash();
        }
        if (this.disabledTimer <= 0) {
          this.disableButton = false;
        }
      }, 1000);
    },
  },
};
</script>
<style>
.splash-btn-container{
  padding-top:1vh;
  padding-bottom:1vh;
  height: 100%;
}
.skip-btn {
  padding: 0 !important; 
  border-radius: 50% !important; 
  overflow: hidden !important; 
}
.skip-btn img {
  object-fit: cover; 
}
.WMBusImg {
  position: absolute;
  bottom: 0;
  right: 0;
}
.vehicle-icon {
  float: right;
  font-size: 36px !important;
  color: red;
  background: white;
  border-radius: 10%;
}
.splash {
  max-width: 100% !important;
  width: 100%;
  background-color: #121434 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.splash-header {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 24px;
  padding: 20px;
  border: 25px;
  margin-left: 20px;
  margin-right: 20px;
}
.splash-main {
  position: fixed !important;
  left: 0;
  width: 100%;
  margin: 0px !important;
  background: #121434 !important;
  color: white;
  padding: 0px;
  margin: 0px !important;
  height: 85vh;
}
.splash-footer {
  position: fixed;
  vertical-align: center;
  bottom: 0px;
  left: 0;
  right: 0;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-right: 2vh;
  padding-left: 2vh;
  margin: 0px !important;
  /* border: 25px; */
  background: rgb(var(--v-theme-splash-footer));
  color: black !important;
  min-height: 50px;  
  height: 15vh;
}
@media (orientation: landscape) {
  .splash-footer {
    padding: 3px;
  }
  .mx-auto {
    /* max-height: 100%; */
    height: 100px;
    width: 100px;
  }

}
.v-overlay__content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.splash-card {
  height: 85vh;
}
.close-splash-button {
  color: white;
  background-color: rgb(150, 0, 0);
}
.play-list {
  height: 85vh;
}
/* .disabled-button-image {
  height:90%;
} */
</style>
