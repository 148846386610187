<template>
  <v-switch 
    v-model="highContrast"
    :class="{
      'custom-switch-on': !highContrast,
      'custom-switch-off': highContrast,
    }"
    :false-icon="'mdi-brightness-5'" 
    :true-icon="'mdi-brightness-3'" 
    inset 
  >
    <template 
      v-if="showLabel"
      v-slot:label 
    >
      Contrast Mode {{ !highContrast ? "Low" : "High" }}
    </template>
  </v-switch>
</template>

<script>
import { useTheme } from "vuetify";
import { mapState, mapActions } from "vuex";

export default {
  props: {
    showLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      highContrast: false,
      theme: useTheme(),
    };
  },
  watch: {
    highContrast() {
      this.updateContrast(this.highContrast);
      this.theme.global.name = this.highContrast
        ? "dark"
        : "light";
    },
  },
  created() {
    this.highContrast = this.highContrastEnabled;
    this.$vuetify.theme.themes.light =
      this.$vuetify.theme.themes["myCustomLightTheme"];
    this.$vuetify.theme.themes.dark =
      this.$vuetify.theme.themes["myCustomDarkTheme"];
    this.theme.global.name = this.highContrast
      ? "dark"
      : "light";
  },
  computed: {
    ...mapState(["highContrastEnabled"]),
  },
  methods: {
    ...mapActions(["updateContrast"]),
    updateContrastSetting() {

    }
  },
};
</script>

<style>
.custom-switch-on,
.custom-switch-off {
  --v-switch-track-height: 24px;
  --v-switch-thumb-size: 24px;
  --v-switch-track-width: 48px;
  transform: scale(1.5);
  margin-left: 100px;
  margin-right: 20px;
}

.custom-switch-on .v-label,
.custom-switch-off .v-label {
  font-size: 12px;
}

.custom-switch-on .mdi-volume-high,
.custom-switch-on .mdi-brightness-5 {
  color: white;
  font-size: 21px;
  color: red;
}

.custom-switch-off .mdi-volume-off,
.custom-switch-off .mdi-brightness-3 {
  color: white;
  font-size: 14px;
  background: black;
}

.custom-switch-off .v-switch__thumb {
  background: black;
}
</style>