<template>
  <div class="time-display">
    <p :aria-label="ariaLabel">{{ formattedTime }}</p>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState(["AppClock"]),
    ariaLabel() {
      return `the current time is ${this.formattedTime}`;
    },
    formattedTime() {
      return this.AppClock.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
    },
  },
  methods: {
    ...mapMutations(["setAppClock"]),
    startTimer() {
      const now = new Date();
      const nextMinute = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours(),
        now.getMinutes() + 1,
        0,
        0
      );
      const msUntilNextMinute = nextMinute - now;

      //makes sure the timer starts on the next minute
      setTimeout(() => {
        this.setAppClock(new Date());
        this.startInterval();
      }, msUntilNextMinute);
    },
    startInterval() {
      this.setAppClock(new Date());
      this.interval = setInterval(() => {
        this.setAppClock(new Date());
      }, 60000);
    },
  },
  mounted() {
    this.startTimer();
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
p {
  font-size: 1.5em;
  text-align: center;
}

.time-display {
  margin-left: 10px;
  padding: 0;
}
</style>
