<template>
<div v-if="messagesToShow.length > 0">
    <message-card v-for="message in messagesToShow" :key="message.id" :message="message" />
    <div v-if="messagesToShow.length > 1" style="height: 10vh;"></div>
</div>
<div v-else>
 <h3>No messages to display</h3>
</div>
</template>

<script>
import MessageCard from './MessageCard.vue';
import { mapGetters } from "vuex";
import {messagesbyPriorityandDateTime} from '../utils/textToSpeechUtils'

export default {
    components: {
        MessageCard
    },
  data() {
    return {
      messages:[],
    };
  },
  props: {
    latestMessageOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    this.messages = this.getmessagesForQRCode
  },
  computed: {
    ...mapGetters([
      "getmessagesForQRCode",
    ]),
    messagesToShow() {
      return this.latestMessageOnly
      ? [...messagesbyPriorityandDateTime(this.messages)].slice(0,1)
      : [...messagesbyPriorityandDateTime(this.messages)];
    },
  },
};
</script>

<style>
</style>