<template>
  <v-switch
    inset
    v-model="localAudioEnabled"
    :label="`Audio Setting ${this.audioEnabled ? 'On' : 'Off'}`"            
    :false-icon="'mdi-volume-off'"
    :true-icon="'mdi-volume-high'"
    :class="{
      'custom-switch-on': this.audioEnabled,
      'custom-switch-off': !this.audioEnabled,
    }"
  >
  </v-switch>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data: () => ({
    localAudioEnabled: true,
  }),
  computed: {
    ...mapState(["audioEnabled"]),
  },
  methods: {
    ...mapActions(["updateAudio" ]),
  },
  watch: {
    localAudioEnabled() {
      this.updateAudio(this.localAudioEnabled);
    },
    },
  created() {    
    this.localAudioEnabled = this.audioEnabled;
  },
};
</script>

<style>
.custom-switch-on,
.custom-switch-off {
  --v-switch-track-height: 24px;
  --v-switch-thumb-size: 24px;
  --v-switch-track-width: 48px;
  transform: scale(1.5);
  margin-left: 100px;
  margin-right: 20px;
}
.custom-switch-on .mdi-volume-high,
.custom-switch-on .mdi-brightness-5 {
  color: white;
  font-size: 21px;
  color: red;
}
.custom-switch-off .mdi-volume-off,
.custom-switch-off .mdi-brightness-3 {
  color: white;
  font-size: 14px;
  background: black;
}
.custom-switch-off .v-switch__thumb {
  background: black;
}
</style>