import axios from 'axios';

var baseURL = process.env.VUE_APP_API_BASE_URL;

const axiosInstance = axios.create({
    baseURL: baseURL, 
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': `fa823e20-d20c-4eb4-b855-47b4318a80ee`, 
        "Access-Control-Allow-Origin": "*",
        'Access-Control-Allow-Credentials':true
    }
});

export default axiosInstance;
