import { timeDifference, getFormattedTime } from "@/utils/timeUtils";
import sanitizeHtml from "sanitize-html";

function getFormatedMonitorDepartureTime(service) {
  let timeString = "";
  let timedifference = timeDifference(service.due);

  if (timedifference <= 0) {
    timeString = " is due now.";
  }
  if (timedifference == 1) {
    timeString = " is due in 1 minute";
  }
  if (timedifference > 1) {
    timeString = " is due in " + timedifference + " minutes.";
  }
  return timeString;
}
export function stopDescriptionToSpeak(stopDescription) {
  let stopDescriptionToSpeak = `Stop ${stopDescription.indicator} ${stopDescription.commonName} on ${stopDescription.street}.`;
  
  return splitwordIntoSentencesThenByWordBoundary(stopDescriptionToSpeak, 100)
}

export function messagesbyPriorityandDateTime(messages) {
  let output = [...messages].sort((a, b) => {
    return (
      a.priority - b.priority || b.type - a.type || new Date(b.dateTime) - new Date(a.dateTime)
    );
  });
  return output;
}

function getEstimatedStopTime(service) {
  
  if (service.via.length <= 0) return "";
  if (service.serviceMessage && service.serviceMessage.toUpperCase() == "CANCELLED") return "";
  
  let stringArray = ["stopping at "];

  service.via.forEach((stop) => {
    if (stop.estimatedMinutesToStop == 1) {
      stringArray.push(
        stop.stopName + " after " + stop.estimatedMinutesToStop + " minute!"
      );
    } else {
      stringArray.push(
        stop.stopName + " after " + stop.estimatedMinutesToStop + " minutes!"
      );
    }
  });

  return stringArray;
}

function extractTextFromHtmlString(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  return doc.body.textContent || "";
}

function splitTextIntoArrayOfSentenses(message) {
  let elementArray = message
    .toString()
    .replace(/([.?!])\s*(?=[A-Z])/g, "$1|")
    .replace(/([,])\s*/g, "$1|")
    .split("|");
  return elementArray;
}

function removeAnchorTags(text) {
  let sanitizedMessage = sanitizedText(text);
  if (sanitizedMessage.includes("<a") && sanitizedMessage.includes("</a>")) {
    return extractTextFromHtmlString(sanitizedMessage);
  }
  return sanitizedMessage;
}

function splitTextByWordBoundary(sentence, maxLength) {
  const regex = new RegExp(`.{1,${maxLength}}(\\s|$)|\\S+?(\\s|$)`, "g");
  let chunks = sentence.match(regex) || [];

  chunks = chunks
    .map((chunk) => chunk.trim())
    .filter((chunk) => chunk.length > 0);

  return chunks;
}

function splitwordIntoSentencesThenByWordBoundary(message, maxLength) {
  let textArray = [];
  let sentences = splitTextIntoArrayOfSentenses(message);

  for (let sentence of sentences) {
    let texts = splitTextByWordBoundary(sentence, maxLength);
    texts.forEach((text) => {
      textArray.push(text);
    });
  }
  return textArray;
}

function sanitizedText(text) {
  return sanitizeHtml(text, {
    allowedTags: ["a"],
    allowedAttributes: {
      a: ["href", "target"],
    },
  });
}

export function messagesToSpeak(messagesForQR, latestMessageOnly) {
  let arrayOfMessages = [];
  let orderedMessages = messagesbyPriorityandDateTime(messagesForQR);

  let myMessages = latestMessageOnly
    ? orderedMessages.slice(0, 1)
    : orderedMessages;

  let messageTexts = myMessages.map((m) => m.text);

  if (messageTexts.length <= 0) return [];

  for (let [i, message] of messageTexts.entries()) {
    message = removeAnchorTags(message);

    let arrayOfTexts = [];
    if (messageTexts.length > 1) {
      arrayOfTexts.push(
        "Message " + (i + 1) + " of " + messageTexts.length + "."
      );
    } else { 
      arrayOfTexts.push('Message')
    }
    let texts = splitwordIntoSentencesThenByWordBoundary(message, 100);
    texts.forEach((text) => {
      arrayOfTexts.push(text);
    });
    if (arrayOfTexts.length > 0) {
      arrayOfMessages.push(arrayOfTexts);
    }
  }

  return arrayOfMessages;
}

function serviceMessage(service)
{
  let firstLine = ''
  if (service.serviceMessage && service.serviceMessage.toUpperCase() == "CANCELLED") {
    firstLine =
      "The number " +
      service.service +
      " " +
      service.providerName +
      " service" +
      " to " +
      service.destination +
      ". Due at " +
      getFormattedTime(service.due) +
      " has been cancelled";
    }
    return firstLine;
}

export function formattedTextVias(via, isMonitored) {
  return isMonitored ? formattedUnmonitoredTextVias(via) : formattedUnmonitoredTextVias(via)
}

export function formattedTextHeader(service) {
  return service.monitored ? formattedmonitoredTextHeader(service) : formattedUnmonitoredTextHeader(service)
}

function formattedUnmonitoredTextHeader(service) {
  let firstLine = "";

  if (service.serviceMessage != null) {    
      firstLine = serviceMessage(service);
    } else {
      firstLine =
      "The number " +
      service.service +
      " " +
      service.providerName +
      " service" +
      " to " +
      service.destination +
      " is due at " +
      getFormattedTime(service.aimedDepartureTime)   

  }
  return splitwordIntoSentencesThenByWordBoundary(firstLine, 100)
    //return firstLine;
}

export function formattedUnmonitoredTextVias(via) {
  return  via.stopName +
  " at " +
  getFormattedTime(via.stopDue, "hh:mm") +
  ". ";
}

function formattedUnmonitoredTextAllVias(service) {
  let arrayOfStrings = [];
  if (service.serviceMessage && service.serviceMessage.toUpperCase() == "CANCELLED") return arrayOfStrings;
  for (let [ i, via ] of service.via.entries()) {
    let addtionalLine = "";
    //first additional line if multiple stops
    if (i === 0 && service.via.length > 1) {
      addtionalLine += "This bus will stop at ";
    }
    //last line
    if (i === service.via.length - 1) {
      addtionalLine += "and terminates at ";
    }

    addtionalLine += formattedUnmonitoredTextVias(via)
    arrayOfStrings.push(addtionalLine);
  }
  return arrayOfStrings;
}
  
function formattedUnmonitoredText(service) {
  let arrayOfStrings = [];
  let firstline = formattedUnmonitoredTextHeader(service);
  arrayOfStrings.push(...firstline)

  if (service.via && service.via.length >= 1) {
    arrayOfStrings.push(...formattedUnmonitoredTextAllVias(service))
  }
  return arrayOfStrings;
}

function formattedmonitoredTextHeader(service)
{
  let firstLine = '';
  if (service.serviceMessage != null && service.serviceMessage.toUpperCase() == "CANCELLED") {    
    firstLine = serviceMessage(service);
    return splitwordIntoSentencesThenByWordBoundary(firstLine, 100)
  } 
  
  firstLine = "The number " +
      service.service +
      " " +
      service.providerName +
      " service" +
      " to " +
      service.destination +
    getFormatedMonitorDepartureTime(service)
  
    return splitwordIntoSentencesThenByWordBoundary(firstLine, 100)
  
  }

function formattedMonitoredText(service) {
  let arrayOfStrings = [];

  //check to see if service message
  arrayOfStrings.push(...formattedmonitoredTextHeader(service));
  let estimatedStopTime = getEstimatedStopTime(service);
  arrayOfStrings.push(...estimatedStopTime);
  return arrayOfStrings;
}

function departureToSpeak(service)
{
  let arrayOfStrings = ''
  if (!service.monitored) {
    arrayOfStrings = formattedUnmonitoredText(service);
  } else {
    arrayOfStrings = formattedMonitoredText(service);
  }
  return arrayOfStrings;
}
export function departuresToSpeak(services) {
  const arrayOfSpeeches = [];

  if (services == null || services.length <= 0) return [];

  for (const service of services) {
    let arrayOfStrings = [];
    arrayOfStrings = departureToSpeak(service)
    arrayOfSpeeches.push(arrayOfStrings);
  }
  return arrayOfSpeeches;
}
