export function setCookie(
  cookieName,
  cookieValue,
  daysToExpire = 365,
  path = "/"
) {

  const expirationDate = new Date();
  expirationDate.setTime(
    expirationDate.getTime() + daysToExpire * 24 * 60 * 60 * 1000
  );
  const expires = "expires=" + expirationDate.toUTCString();

  const sameSite = "SameSite=None";
  const secure = "Secure";

  document.cookie =
    cookieName +
    "=" +
    encodeURIComponent(cookieValue) +
    ";" +
    expires +
    ";path=" +
    path +
    ";" +
    sameSite +
    ";" +
    secure;
}

export function getCookie(cookieName) {
  const cookies = document.cookie.split("; ");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const [name, value] = cookie.split("=");

    if (name === cookieName) {
      return decodeURIComponent(value);
    }
  }

  return null;
}

export function deleteCookie(cookieName, path = "/") {
  document.cookie =
    cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=" + path;
}

export function deleteAllCookies() {
  const cookies = document.cookie.split("; ");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const [name] = cookie.split("=");

    deleteCookie(name);
  }
}
