<template>
  <div class="app-footer" :class="getAppFooterClass">
    <information-popup 
      v-if="this.currentPage == 'timetable'" 
      class="information-popup" 
      :latestMessageOnly="true"
      :messageType="messageType"
    /> 
    <speech-player 
      v-if="showAudioPlayer" 
      class="audio-player" 
      :messageType="messageType" 
    />
  </div>
</template>
<script>
import InformationPopup from "./InformationPopup.vue";
import SpeechPlayer from "../SpeechPlayer.vue";
import { MessageType } from "../../constants";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    SpeechPlayer,
    InformationPopup,
  },
  props: {
    messageType: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      isMessagesOpen:
        this.messageType === MessageType.INITIALLY_MINIMISED ? false : true,
      isFullScreenMessage:
        this.messageType === MessageType.INITIALLY_MINIMISED ||
          this.messageType === MessageType.TIME_OUT
          ? false
          : true,
    };
  },
  watch: {
    messageType: {
      handler: function (newVal) {
        if (newVal === MessageType.INITIALLY_MINIMISED) {
          this.setPopupMinimised(true)
        } else {
          this.setPopupMinimised(false)
        }
        if (
          newVal === MessageType.INITIALLY_MINIMISED ||
          newVal === MessageType.TIME_OUT
        ) {
          this.isFullScreenMessage = false;
        } else {
          this.isFullScreenMessage = true;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState(["currentPage", "audioEnabled", "popupMinimised", "popupPreviouslyMinimised"]),
    showAudioPlayer() {
      return this.audioEnabled && this.currentPage != "links";
    },
    getAppFooterClass() {
      if (this.popupMinimised) return "app-footer-closed";

      let classes = this.isFullScreenMessage
        ? "app-footer-large"
        : "app-footer-small";
      return classes;
    },
  },
  methods: {
    ...mapMutations(["setPopupMinimised"]),

  },
  mounted() {
    if (this.popupMinimised == true || this.popupPreviouslyMinimised == true) {
      this.setPopupMinimised(true)
    } else {
      this.setPopupMinimised(this.messageType === MessageType.INITIALLY_MINIMISED)
    }
  },
};
</script>

<style scoped>
.information-popup {
  height: 10vh;
}

.progress-bar {
  min-height: 3vh !important;
  max-height: 3vh !important;
  background-color: rgb(var(--v-theme-background-dark)) !important;
  border: none !important;
  border-radius: 0px;
  width: 100%;
  overflow-y: hidden;
  flex: 1;
}

.app-footer {
  position: relative;
  text-align: center;
  width: 100%;
  color: black;
  background-color: lightgrey;
}

.app-footer-large {
  height: 100vh;
  z-index: 1010;
}

.app-footer-small {
  max-height: 50vh;
}

.audio-player {
  flex: 1;
  width: 100%;
  min-height: 75px;
  max-height: 100px;
  height: 15vh;

  background-color: rgb(var(--v-theme-speech-player)) !important;
}

.messages {
  flex: 1;
  min-height: 5vh;
  position: relative;
  overflow: auto;
}

.progress-bar {
  flex: 1;
  min-height: 2vh !important;
  max-height: 2vh !important;
  background-color: green;
}
</style>