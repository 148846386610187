export function timeDifference(dateTime, dateTime2 = null, appClock = new Date()) {
    const date = new Date(dateTime);
    const date2 = dateTime2 == null ? appClock : new Date(dateTime2);
    const diff = date - date2;
    return Math.floor(diff / 60000);
}
export function getFormattedTime(time, format = "LT") {
    var moment = require("moment");
    return moment(time).format(format);
}

