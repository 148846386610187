<template>
    <div>
        <h1>Links</h1>
        <v-card v-for="link in localLinks" :key="link.title">
            <v-card-title>{{ link.title }}</v-card-title>
            <v-card-text>
                <a :href="link.url" target="blank">{{ link.url }}</a>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapState,} from "vuex";
export default {
    data() {
        return {
            localLinks: []
        };
    },
    mounted() {
        this.localLinks = this.links;
    },
    computed: {
        ...mapState([ "links",]),
    }
}
</script>
<style>
</style>