<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center align-center">
    <v-row class="ma-0 pa-0">
      <v-col cols="4" class="d-flex justify-center align-center">
        <v-img
          max-width="70px"
          max-height="70px"
          @click="handleRewind"
          :src="backImage"
          :class="{ 'black-and-white': rewindIsClicked }"
          alt="back"
          class="image fastword-image"
        />
      </v-col>
      <v-col cols="4" class="d-flex justify-center align-center">
        <v-img
          max-width="80px"
          max-height="80px"
          @click="handlePlayPauseBtnClick"
          :src="isSpeaking ? stopImage : playImage"
          alt="play"
          class="image play-image"
        />
      </v-col>
      <v-col cols="4" class="d-flex justify-center align-center">
        <v-img
          max-width="70px"
          max-height="70px"
          @click="handleFastforward"
          :src="skipImage"
          :class="{ 'black-and-white': fastforwardIsClicked }"
          alt="skip"
          class="image rewind-image"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import { mapState, mapGetters } from "vuex";
import {
  stopDescriptionToSpeak,
  messagesToSpeak,
  departuresToSpeak,
} from "@/utils/textToSpeechUtils";

import { MessageType } from "../constants";

export default {
  components: {},
  name: "SpeechPlayer",
  props: {
    messageType: {
      type: String,
      required: false,
      default: "",
    },
  },

  data() {
    return {      
      fastforwardIsClicked: false,
      rewindIsClicked: false,

      currentSentenceIndex: 0,
      isSpeaking: false,
      currentSentence: [],
      currentSentencePartIndex: 0,

      speechPlaying: false,
      currentMessage: 0,
      currentService: 0,
      currentSpeech: 0,
      readMessages: true,
      playImage: require("@/assets/icons/playBtn.svg"),
      stopImage: require("@/assets/icons/stopBtn.svg"),
      skipImage: require("@/assets/icons/skipBtn.svg"),
      backImage: require("@/assets/icons/backBtn4.svg"),
      svg: require("@/assets/icons/audioBtn1.svg"),
    };
  },
  computed: {
    ...mapState(["AppClock", "stopDescription", "repressServices", "currentPage"]),
    ...mapGetters([
      "getServiceForQRCode",
      "getmessagesForQRCode",
      "messagesbyPriorityandDateTime",
      "stopDescription",
    ]),
  },
  methods: {
    textToSpeakArray() {
      let textArray = [];

      let includeStopDescription = true;
      let latestMessageOnly = true;
      let includeServices = true;
      let departures = [];

      //if currentPage is timetable add the stop description, 1 x messages and services
      if (this.currentPage == "timetable" && this.messageType != MessageType.NON_DISMISSABLE)
      {
        includeStopDescription = true;
        latestMessageOnly = true;
        includeServices = true;        
      }
      
      //if currentPage is timetable and critical message add the stop description and ALL messages 
      if (this.currentPage == "timetable" && this.messageType == MessageType.NON_DISMISSABLE)
      {
        includeStopDescription = true;
        latestMessageOnly = true;
        includeServices = false;         
      }
      
      //if currentPage is messages add the ALL messages
      if (this.currentPage == "messages" )
      {
        includeStopDescription = true;
        latestMessageOnly = false;
        includeServices = false;         
      }
      
      if (includeStopDescription)
      {
        let formatedStopDescription = stopDescriptionToSpeak(this.stopDescription);
        textArray.push(formatedStopDescription);
      }

      let messages = messagesToSpeak(
        this.getmessagesForQRCode,
        latestMessageOnly
        );

      if (messages.length > 0) textArray.push(...messages);

      if (includeServices) {
        let services = this.getServiceForQRCode;
        departures = departuresToSpeak(services);
      }

      if (departures.length > 0) textArray.push(...departures);

      return textArray;
    },
    applyBlackAndWhiteToFastforward() {
      this.fastforwardIsClicked = true;
      setTimeout(() => {
        this.fastforwardIsClicked = false;
      }, 300);
    },
    applyBlackAndWhiteToRewind() {
      this.rewindIsClicked = true;
      setTimeout(() => {
        this.rewindIsClicked = false;
      }, 300);
    },
    handleRewind() {
      this.applyBlackAndWhiteToRewind();
      this.currentSentenceIndex--;
      if (this.currentSentenceIndex < 0) {
        this.currentSentenceIndex = this.textToSpeakArray().length - 1;
      }
      this.isSpeaking = false;
      speechSynthesis.cancel();
      this.speak();
    },
    handleFastforward() {
      this.applyBlackAndWhiteToFastforward();
      this.currentSentenceIndex++;
      if (this.currentSentenceIndex >= this.textToSpeakArray().length) {
        this.currentSentenceIndex = 0;
      }
      this.isSpeaking = false;
      speechSynthesis.cancel();
      this.speak();
    },
    stopSpeaking() {
      this.currentSentenceIndex = 0;
      this.isSpeaking = false;
      this.currentSentence = [];
      this.currentSentencePartIndex = 0;
      speechSynthesis.cancel();
    },
    speak() {
      if (!this.isSpeaking) this.processArray();
    },
    processArray() {
      let arrayToSpeak = this.textToSpeakArray();
      if (this.currentSentenceIndex < arrayToSpeak.length) {
        this.isSpeaking = true;
        this.currentSentence = arrayToSpeak[this.currentSentenceIndex];
        this.currentSentencePartIndex = 0;
        this.speakCurrentSentencePart();
      } else {
        this.currentSentenceIndex = 0;
        this.isSpeaking = false;
        speechSynthesis.cancel();
      }
    },
    speakCurrentSentencePart() {
      if (this.currentSentencePartIndex < this.currentSentence.length) {
        const utterance = new SpeechSynthesisUtterance(
          this.currentSentence[this.currentSentencePartIndex]
        );

        utterance.onend = () => {
          this.currentSentencePartIndex++;
          this.speakCurrentSentencePart(); // Speak the next part of the current sentence
        };

        window.speechSynthesis.speak(utterance);
      } else {
        this.currentSentenceIndex++;
        this.processArray(); // Move to the next sentence in the queue
      }
    },  

    handlePlayPauseBtnClick() {
      if (this.isSpeaking) {
        this.stopSpeaking();
      } else {
        this.speak();
      }
    },
  },
};
</script>

<style>
@media (orientation: landscape) {
  .play-image {
  max-height: 70% !important;
}
.rewind-image{
  max-height: 70% !important;
}
.fastword-image{
  max-height: 70% !important;
}
}
.image {
  width: 100%;
  height: auto;
  transition: filter 0.5s ease;
}
.my-contrast-switch {
  margin-left: 300px;
  margin-right: 20px;
}

/* .darken-image {
  filter: brightness(0.7); 
} */
.black-and-white {
  filter: grayscale(100%);
}
</style>