<template>
  <div>
    <div v-if="loading && getServiceForQRCode == null">
      <v-skeleton-loader 
        v-for="n in 5" 
        type="list-item-avatar" 
        :key="n">
      </v-skeleton-loader>
    </div>    
    <v-data-table v-else-if="getServiceForQRCode && getServiceForQRCode.length > 0"
      align="start"
      class="timetable-table"
      header-align="start"
      hide-default-header
      hide-default-footer
      item-class="getRowClass"
      item-key="id"
      show-expand
      single-expand
      v-model:expanded="expanded"
      :headers="serviceHeaders"
      :items="getServiceForQRCode"
      :items-per-page="-1" 
      @update:expanded="(newExpanded) => (expanded = newExpanded.slice(-1))"
      :loading="loading"
    >

      <template v-slot:[`item.service`]="{ item }">
        <strong :aria-label="ariaLabelService(item)">{{ this.UpperCase(item.service) }}</strong>
      </template>
      <template v-slot:[`item.destination`]="{ item }">
        <strong  :aria-label="ariaLabelService(item)">{{ item.destination }}</strong>
        <div v-if="this.UpperCase(item.serviceMessage) == 'CANCELLED'" class="text-error">{{ this.UpperCase(item.serviceMessage) }}</div>
      </template>
      <template v-slot:[`item.due`]="{ item }">
        <strong :aria-label="ariaLabelService(item)">{{ getDepartureTimeToDisplay(item) }}</strong>
      </template>
      <template v-slot:expanded-row="{ columns, item }">
        <tr :class="getRowClass(item)">
          <td :colspan="columns.length" class="expanded-row-td ma-0 pa-0">
            <v-container fluid class=" ma-0 pa-0" v-if="imageSource(item.provider) != ''">
              <v-row class="ma-0 d-flex justify-end align-center">
                <v-col :cols="5"><div>Operated by</div></v-col>
                <v-col
                  :cols="7"
                  class="padding-bottom d-flex justify-end align-center"
                  ><img
                    :src="imageSource(item.provider)"
                    :alt="item.providerName"
                    class="operatorImg"
                /></v-col>
              </v-row>
              <div v-if="isMonitored(item)" class="real-time-message ma-0 pa-0 px-5">Estimated journey time to stop</div>
              <div v-else class="real-time-message ma-0 pa-0 px-5">Timetable for next stops</div>
            </v-container>
          </td>
        </tr>

        <tr :class="getRowClass(item)">
          <td :colspan="columns.length" class="timeline-td">
            <div>
              <v-timeline align="start" side="end" class="custom-timeline">
                <v-timeline-item
                  v-for="(stop, index) in item.via"
                  :key="index"
                  size="small"
                  :class="getClass(index, item.via.length)"
                >
                  <div class="my-flex">
                    <div class="row1">
                      {{ stop.stopName }}
                      <div class="text-caption">
                        {{ stop.stopMessage }}
                      </div>
                    </div>
                    <div class="row2">
                      <div class="subsequent-stop-time me-4">{{ subsequentStopTime(stop, isMonitored(item)) }}</div>
                    </div>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div v-else>No data available.</div>
    <!-- filler to make navbar display correctly on when speech player visable -->
    <div :class="audioEnabled ? 'filler-large' : 'filler'"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { formattedTextHeader, } from "@/utils/textToSpeechUtils";
import { timeDifference, getFormattedTime } from "@/utils/timeUtils";

export default {
  name: "UI",
  components: {},
  computed: {
    ...mapState(["AppClock", "audioEnabled", "loading"]),  
    ...mapGetters(["getServiceForQRCode"]),  
    getDepartureTimeToDisplay() {
      return (item) => {
        let timeString = '';
        if (!item.monitored) {
          timeString = getFormattedTime(item.aimedDepartureTime, "HH:mm");
        } else {
          let timedifference = timeDifference(item.due, this.AppClock);

          if (timedifference <= 0) {
            timeString = "Due";
          } else if (timedifference === 1) {
            timeString = timedifference + " min";
          } else if (timedifference > 1) {
            timeString = timedifference + " mins";
          }
        }
        return timeString;
      };
    }
  },
  data() {
    return {
      expanded: [],
      serviceHeaders: [
        {
          title: "Serv",
          align: "start",
          sortable: false,
          key: "service",
          width: '10%' 
        },
        {
          title: "Destination",
          align: "start",
          sortable: false,
          key: "destination",
          width: '60%' 
        },
        {
          title: "Due",
          align: "start",
          sortable: false,
          key: "due",
          width: '30%' 
        },
      ],
    };
  },
  methods: {

    ariaLabelService(item) {
      return formattedTextHeader(item);
    },
    isMonitored(item) {
      return item.monitored;
    },
    getRowClass(item) {
      const index = this.getServiceForQRCode.indexOf(item);
      return index % 2 === 0 ? "odd-row" : "even-row";
    },
    imageSource(provider) {
      try {
        return `https://centro.rslepi.co.uk/DownloadableFileAssets/Shared/operator_logos/${provider}.PNG`
      } catch (error) {
        return "";
      }
    },
    UpperCase(string) {
      if (string == undefined) return;
      return string.toUpperCase();
    },
    getClass(index, viasLength) {
      return index === viasLength - 1
        ? `time-line-item-last`
        : `time-line-item-${index}`;
    },
    subsequentStopTime(stop, monitored) {
      if (!monitored) {
        return  getFormattedTime(stop.stopDue, "HH:mm")
      } else {
        return  stop.estimatedMinutesToStop + " mins"
      }
    }
  },  
};
</script>

<style>

.v-data-table__td {
  padding-right: 0px !important;
}
.v-data-table__td--expanded-row {
  padding-right: 8px !important;
  padding-left: 0px !important;
}

.real-time-message{
  background-color: rgb(146, 146, 146) ;
  color: white;
  text-align: center;
  height: 30px;
}
.filler {
  height: 5vh;
  width: 100%;
}
.filler-large {
  height: 20vh;
  width: 100%;
}
.timeline-td {
  padding-bottom: 50px !important;
}

.expanded-row-td {
  border: none !important;
}

.timetable-table .odd-row {
  background-color: rgb(
    var(--v-theme-on-surface-variant)
  ) !important; /* Light grey color */
  color: rgb(var(--v-theme-surface-variant-2)) !important;
}

.v-timeline-item__opposite {
  display: none !important;
}
.my-flex {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 4fr 1fr;
}
.row1 {
  grid-column: 1;
}
.row2 {
  grid-column: 2;
  align-content: flex-start;
  min-width: 100px;
}
.row3 {
  grid-column: 3;
  align-content: flex-start;
}
/* .margin-bottom {
  margin-bottom: 14vh;
} */
.padding-bottom {
  padding-bottom: 10px !important;
}
/* .right-align {
  text-align: right;
} */
.operatorImg {
  max-width: 100%; 
  height: auto; 
  object-fit: contain;
  max-height: 60px;
  background-color:  rgb(var(--v-theme-background-dark));
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.timetable-table thead th {
  border: 10px;
  background-color: rgb(var(--v-theme-popup-primary)) !important;
  color: rgb(var(--v-theme-on-surface-variant)) !important;
}
.timetable-table thead {
  background-color: rgb(var(--v-theme-popup-primary)) !important;
}

.timetable-table th {
  border: rgb(var(--v-theme-popup-primary)) !important;
}

.timetable-table {
  font-size: 20px !important;
  border-radius: 0 !important;
}

.timetable-table .v-data-table__tr:nth-child(odd) {
  background-color: rgb(
    var(--v-theme-on-surface-variant)
  ) !important; /* Light grey color */
  color: rgb(var(--v-theme-surface-variant-2)) !important;
  border-radius: 0;
}

/* .custom-timeline .v-timeline-divider__after,
.custom-timeline .v-timeline-divider__before {
  background-color:white;
} */
.v-data-table__tr:nth-child(even) .v-timeline-divider__after,
.v-data-table__tr:nth-child(even) .v-timeline-divider__before {
  background-color: white !important;
}
.v-data-table__tr:nth-child(odd) .v-timeline-divider__after,
.v-data-table__tr:nth-child(odd) .v-timeline-divider__before {
  background-color: black !important;
}

.custom-timeline .v-timeline-divider__dot {
  background-color: rgb(var(--v-theme-surface-variant-2)) !important;
}

.v-timeline-divider__before,
.v-timeline-divider__after,
.v-timeline-divider__dot {
  background-color: rgb(var(--v-theme-surface-variant-2)) !important;
}

.even-row .v-timeline-divider .v-timeline-divider__before,
.even-row .v-timeline-divider .v-timeline-divider__after,
.even-row .v-timeline-divider .v-timeline-divider__dot
 {
  background-color: rgb(var(--v-theme-divider)) !important;
}
.even-row .v-timeline-divider__inner-dot {

  background-color: rgb(var(--v-theme-background)) !important;
}


.custom-timeline .v-timeline-item:first-of-type .v-timeline-divider::before,
.custom-timeline .v-timeline-item:last-of-type .v-timeline-divider::after {
  display: none !important;
}

.time-line-item-0 .v-timeline-divider .v-timeline-divider__before,
.time-line-item-last .v-timeline-divider .v-timeline-divider__after {
  display: none !important;
} 


.v-timeline-divider__inner-dot {
  background-color: white !important;
}
.time-line-item-last .v-timeline-divider__inner-dot {
  background-color: red !important;
}


.service-message {
  color: primary;
  font-size: 16px;
  font-weight: 500;
}

.text--primary {
  color: var(--v-primary);
}

.custom-class {
  background: rgb(var(--v-theme-error));
}
</style>