<template>
  <div>
    <h1>Information Messages</h1>
    <stop-messages/>
  </div>
</template>

<script>
import StopMessages from './StopMessages.vue'
export default {
  components: { StopMessages },

}
</script>

<style>

</style>