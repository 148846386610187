<template>
  <v-card class="message-card bg-message">
    <v-card-text>
      <span v-html="sanitizedText"></span>
    </v-card-text>
  </v-card>
</template>

<script>
import sanitizeHtml from 'sanitize-html';

export default {
  props: {
    message: {
      type: Object,
      required: true,
      default: null,
    },
  },
  computed: {
    sanitizedText() {
      return sanitizeHtml(this.message.text, {
        allowedTags: ["a", "b", "i", "em","strong", "p", "br"],
        allowedAttributes: {
          a: ["href", "target"],
        },
      });
    },
  },
};
</script>

<style>
message-card {
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;

  font-size: 16px !important;

  text-align: left;
  transition: all 0.3s;
  background: rgb(var(--v-theme-error));
}

.message-card span {
  font-size: 20px !important;
}
</style>