<template>
  <v-app>
    <v-container>
      <app-head class="app-head" :stopDescription="stopDescription" />
      <nav-drawer
        v-if="!disableMenu"
        @showCookieSettings="showCookieSettings"
      />
      <message-page
        v-if="currentPage === 'messages'"
        class="message-page"
        :class="[isSafari ? 'message-page-short-height' : 'not-safari']"
        :latestMessageOnly="false"
      />
      <links-page v-if="currentPage === 'links'" class="links-page" />
      <div
        class="main-content"
        :class="[isSafari ? 'short-max-height' : 'not-safari']"
      >
        <time-table v-if="currentPage === 'timetable'" class="time-table" />
      </div>
    </v-container>

    <splash-screen-fixed v-if="displaySplashScreen" class="splash-screen" />
    <app-footer class="app-footer" :messageType="messageType" />
    <vue-cookie-accept-decline
      elementId="cookiepreference"
      position="bottom-right"
      ref="cookiepreference"
      transitionName="slideFromBottom"
      type="floating"
      :debug="false"
      :disableDecline="false"
      :showPostponeButton="false"
      @clicked-accept="cookieClickedAccept"
      @clicked-decline="cookieClickedDecline"
    >
      <template #message>
        <div class="text-primary">
          We use cookies to ensure you get the best experience on our website.
        </div>
        <a href="/cookies.html" target="_blank">Learn More...</a>
      </template>
      <template #declineContent>Decline</template>
      <template #acceptContent>Agree</template>
    </vue-cookie-accept-decline>
  </v-app>
</template>

<script>
import NavDrawer from "./components/NavDrawer.vue";
import AppHead from "./components/AppHead.vue";
import SplashScreenFixed from "./components/SplashScreenFixed.vue";
import TimeTable from "./components/TimeTable.vue";
import MessagePage from "./components/MessagePage.vue";
import LinksPage from "./components/LinksPage.vue";
import AppFooter from "./components/AppFooter.vue";
import VueCookieAcceptDecline from "vue-cookie-accept-decline";
import { MessageType } from "../constants";

import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "App",
  components: {
    NavDrawer,
    AppHead,
    SplashScreenFixed,
    TimeTable,
    MessagePage,
    LinksPage,
    AppFooter,
    VueCookieAcceptDecline,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters([
      "cookiePreference",
      "getmessagesForQRCode",
      "displaySplashScreen",
    ]),
    ...mapState([
      "qRCode",
      "currentPage",
      "disableMenu",
      "timeoutMessageType",
      "fullScreenMessageType",
      "nonDismissableMessageType",
      "stopDescription",
      "pageTitle",
    ]),

    messageType() {
      let maxType = this.maxType;

      if (maxType >= this.nonDismissableMessageType)
        return MessageType.NON_DISMISSABLE;
      if (maxType >= this.fullScreenMessageType)
        return MessageType.FULL_SCREEN;
      if (maxType >= this.timeoutMessageType)
        return MessageType.TIME_OUT;

      return MessageType.INITIALLY_MINIMISED;
    },
    maxPriority() {
      let maxPriority = null;
      let messagePriority = null;
      let messages = this.getmessagesForQRCode;

      if (messages.length > 0) {
        messagePriority = messages.map((x) => x.priority);
        maxPriority = Math.max(...messagePriority);
      }
      return maxPriority;
    },
    maxType() {
      let maxType = null;
      let messageType = null;
      let messages = this.getmessagesForQRCode;
      if (messages.length > 0) {
        messageType = messages.map((x) => x.type);
        maxType = Math.max(...messageType);
      }
      return maxType;
    },
  },
  methods: {
    ...mapMutations(["recreateAllCookies", "deleteAllCookies", "setQRCode"]),
    ...mapActions(["updateRepressServices", "updateCurrentPage", "fetchData"]),
    showCookieSettings() {
      this.$refs.cookiepreference.removeCookie();
      this.$refs.cookiepreference.init();
    },
    cookieClickedAccept() {
      this.recreateAllCookies();
    },
    cookieClickedDecline() {
      this.deleteAllCookies();
    },
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
  },
  mounted() {
    this.updateRepressServices(false);
    document.title = this.pageTitle 

    setTimeout(() => {
      window.scrollTo(0, 1);
    }, 0);
  },
  created() {
    this.setQRCode(this.$route.params.id);
  },
  watch: {
    $route() {
      this.setQRCode(this.$route.params.id);
      this.fetchData(this.$route.params.id);
    },
  },
};
</script>

<style >
:root {
  --toolbar-height: 64px;
  --min-toolbar-height: 64px;
  --app-head-top: 64px
}

.app-footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.information-popup {
  margin: 0px;
  width: 100%;
  overflow-y: auto;

  flex: 1;
  min-height: 5vh;
  position: relative;
}

.time-table {
  position: fixed !important;
  top: calc(max(var(--toolbar-height) , var(--min-toolbar-height)) + var(--app-head-top));
  max-height: 80vh;
  background-color: surface;
  border: none !important;
  border-radius: 0px;
  left: 0;
  width: 100%;
  overflow-y: auto;
}

.nav-drawer {
  position: fixed !important;
  top: 0vh;
  left: 0;
  width: 100%;
  z-index: 1008 !important;
  height: 100vh !important;
}
.app-head {
  position: fixed !important;
  top: var(--app-head-top) !important;
  height: var(--toolbar-height);
  min-height: var(--min-toolbar-height);
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: blue !important;
}

.splash-screen {
  position: fixed !important;
  top: 0vh;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1015;
}

.message-page {
  position: fixed !important;
  top: calc(max(var(--toolbar-height) , var(--min-toolbar-height)) + var(--app-head-top));
  max-height: 80vh;
  background-color: surface;
  border: none !important;
  border-radius: 0px;
  left: 0;
  width: 100%;
  overflow-y: auto;
}
.message-page-short-height {
  max-height: 70vh !important;
}
.links-page {
  position: fixed !important;
  top: calc(max(var(--toolbar-height) , var(--min-toolbar-height)) + var(--app-head-top));
  max-height: 80vh;
  background-color: surface;
  border: none !important;
  border-radius: 0px;
  left: 0;
  width: 100%;
  overflow-y: auto;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  height: 101vh; /* Allow scroll to dismiss ios browser address bar*/
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.menu-app-bar{
  height: var(--app-head-top) !important;
}
@media (orientation: landscape) {
  .message-page{    
    max-height: 80vh;
  }
  .links-page {
    max-height: 80vh;
  }
}
</style>

